import React, { useEffect, useState } from "react"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"
import ArrowIcon from "../ArrowIcon"

const AmazonCatalogCarousal = ({ RnRSlideDetails }) => {
  const [mobileScreen, setMobileScreen] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  const visibleSlides = mobileScreen ? 1 : 4 // Number of slides visible at a time
  const totalSlides = RnRSlideDetails?.length
  const maxSlideIndex = totalSlides - visibleSlides // Max index before stopping the right arrow

  useEffect(() => {
    if (typeof window !== "undefined") {
      setMobileScreen(window.innerWidth <= 1023)
    }
  }, [])

  const handleSlideChange = event => {
    setCurrentSlide(event.detail.currentSlide)
  }

  return (
    <CarouselProvider
      className="max-w-7xl px-10 relative mx-auto max-h-1500 h-auto xl:h-auto"
      naturalSlideWidth={100}
      naturalSlideHeight={90}
      totalSlides={totalSlides}
      visibleSlides={visibleSlides}
      isPlaying={true}
      interval={4000}
      infinite={false} // Disable infinite loop for better control
      dragEnabled={true}
      currentSlide={currentSlide} // Sync the current slide with the state
      onChange={handleSlideChange} // Update state on slide change
    >
      <Slider className="z-0 grid w-full items-start lg:items-center min-h-250px md:h-72 lg:h-auto lg:min-h-96">
        {RnRSlideDetails?.map((slide, index) => (
          <Slide key={index} index={index}>
            <div
              className={`grid bg-white p-4 mx-4 border border-gray-150 rounded-xl h-60`}
            >
              <div className="flex justify-center w-full">
                <img
                  src={`https://res.cloudinary.com/vantagecircle/image/upload/v1724132987/vantagecircle/amazon-cost-saving/${slide.slideImage}`}
                  className="w-full h-36 rounded-lg object-cover"
                />
              </div>
              <p className="text-left mt-4 text-gray-900">
                {slide.slideDescription}
              </p>
            </div>
          </Slide>
        ))}
      </Slider>

      <div className="pt-7 lg:pt-0 flex justify-center items-center">
        <ButtonBack
          className={`border border-newOrange mx-1 xl:mx-0 mt-0 eb-carousel-btn xl:absolute left-2 transform xl:-translate-x-8 transform-y-10 rotate-180 bg-white p-3 md:p-4 rounded-full`}
          style={{ bottom: "55%" }}
          disabled={currentSlide === 0} // Disable if on the first slide
          onClick={() => setCurrentSlide(currentSlide - 1)} // Navigate back
        >
          <ArrowIcon />
        </ButtonBack>
        <ButtonNext
          className={`border border-newOrange mx-1 xl:mx-0 xl:mt-0 eb-carousel-btn xl:absolute right-2 transform xl:translate-x-8 transform-y-10 bg-white p-3 md:p-4 rounded-full`}
          style={{ bottom: "55%" }}
          disabled={currentSlide >= maxSlideIndex} // Disable if we are at or past max slide index
          onClick={() => setCurrentSlide(currentSlide + 1)} // Navigate forward
        >
          <ArrowIcon />
        </ButtonNext>
      </div>
    </CarouselProvider>
  )
}

export default AmazonCatalogCarousal
